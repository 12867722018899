.tableAvatar {
  display: flex;
  width: 100%;
  align-items: center;

  &__img {
    margin-right: 12px;
    width: 50px;
    height: 50px;

    &__profile {
      border-radius: 50%;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
}

.borderImg {
  border: 3px solid #ffae00;
}
