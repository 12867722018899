.performanceChartScoreCard {
  position: inherit;
  width: 100% !important;
  height: 395px !important;
}

.performanceChartScoreCard > div > svg {
  border-radius: 12px;
  background-color: #ffffff;
  width: 100%;
  height: 100%;
}
