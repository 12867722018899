.PerformanceTrackingGraph {
  position: relative;
}
.legendTracking {
  display: flex;
  flex-direction: row;
  & > span {
    color: white;
    font-size: 14px;
    margin-right: 20px;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
.legendBefore {
  height: 2px;
  width: 10px;
  position: inherit;
  margin-right: 10px;
}

.PerformanceTrackingGraph > div > .recharts-legend-wrapper {
  bottom: -25px !important;
}
