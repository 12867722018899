@import "../../styles/variables.scss";

.datesContainer {
  justify-content: space-between;
}
.datesContainer .dateFieldContainer div {
  width: 100% !important;
}

.datesContainer .dateFieldContainer {
  flex-grow: 1;
}

.datesContainer .dateFieldContainer > div > div > div {
  display: flex;
  justify-content: flex-end;
}

.datesContainer .dateFieldContainer div div {
  height: 49px;
  border: 1px solid $color-white-secondary;
  border-radius: 6px;
  background-color: transparent;
  &:hover {
    outline: none !important;
  }
}

.datesContainer .dateFieldContainer div .Mui-disabled .Mui-disabled {
  -webkit-text-fill-color: #969698;
}

.MuiCalendarPicker-viewTransitionContainer
  .PrivatePickersSlideTransition-root
  div
  div
  div
  .Mui-disabled {
  color: #969698;
}

.datesContainer .dateFieldContainer div div .MuiInputAdornment-root {
  border: none;
  height: 40px;
  width: 40px !important;
  margin: 0px;
  padding: 0px;
}
.MuiCalendarPicker-root .css-1dozdou {
  max-width: 100%;
}

.css-epd502 .MuiCalendarPicker-root {
  max-width: 320px !important;
  height: auto !important;
}

.picker-button {
  border: 1px solid;
}

.MuiInputAdornment-root button .MuiSvgIcon-root {
  fill: white !important;
}

.col-lg-6 .MuiFormControl-root {
  margin-right: 12px;
}

.MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.css-dhopo2 {
  overflow-y: auto !important;
}
