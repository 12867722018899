.clothBox {
  width: 165px !important;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  & > img {
    width: 100%;
    object-fit: contain;
  }
}

.swagBox {
  &:nth-child(2) {
    border-left: 1px solid #c4c4c4;
    border-right: 1px solid #c4c4c4;

    @media screen and (max-width: 992px) {
      border-left: none;
      border-right: none;
    }
  }
}
.winners{
  flex: 1;
  height: 300px;
  overflow: hidden;
  overflow-y: visible;
}
.winnerBox {
  display: flex;
  
  @media screen and (max-width: 1350px) and (min-width: 992px) {
    flex-direction: column;
    align-items: center;
  }
  @media screen and (max-width: 992px) and (min-width: 600px) {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;
  }
  @media screen and (max-width: 600px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 50px;
  }
}
