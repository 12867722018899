.container {
  width: calc(100vw - 298px);
  position: relative;
  overflow: auto;
}

.table {
  table-layout: fixed;
  width: 100%;
  & tr {
    & th:nth-of-type(1),
    td:nth-of-type(1) {
      width: 50px;
      position: sticky;
      left: 0px;
      z-index: 20;
    }
    & th:nth-of-type(2),
    td:nth-of-type(2) {
      position: sticky;
      left: 57px;
      z-index: 20;
    }
  }
}
