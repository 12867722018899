@import "../../styles/variables.scss";

.dialogBox {
  background-color: theme-color(background-color-default);
  border-radius: 12px;
  border: none;
  width: 800px;
  padding: 0px !important;
  box-sizing: border-box;
}

.closeButton {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  background-color: theme-color(background-color);
  position: absolute;
  top: 24px;
  right: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.copperBox {
  padding: 18px;
}

.headerCropper {
  display: flex;
  height: 50px;
  align-items: center;
}