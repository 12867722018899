@import "../../../../styles/variables.scss";

.rank {
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tableAvatar {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  &__img {
    & > img {
      border-radius: 50%;
      width: 50px;
      height: 50px;
      object-fit: cover;
    }
  }
}

.schedule {
  color: theme-color(mainOrange);
}

.failure {
  color: theme-color(secRed);
}

.deliver {
  color: theme-color(mainGreen);
}
