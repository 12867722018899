.icon {
  background-color: #a47f511a;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}

.title {
  color: #a47f51 !important;
  text-decoration: underline;
  text-underline-offset: 2px;
  margin-left: 5px !important;
}

.date {
  color: #c4c4c4;
}
