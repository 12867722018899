@import "../../../../../styles/variables.scss";

.headerClient {
  display: flex;
  justify-content: space-between;
  height: 50px;
}

.container {
  width: 100%;
  overflow: hidden;
  margin-top: -2px;
}

.header {
  opacity: 0.6;
  position: relative;
  text-align: center;
}

.correctAnswerText {
  color: theme-color(secondary);
  text-decoration: underline;
  text-underline-offset: 2px;
  cursor: pointer;
}

.rank {
  border: "2px";
  border-style: solid;
  border-radius: 6px;
  padding: 12px 10px;
  display: inherit;
}

.aqua {
  border-color: theme-color(mainAqua);
}
