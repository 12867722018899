@font-face {
  font-family: "Inter-Black";
  src: url(../assets/fonts/Inter-Black.ttf);
}

@font-face {
  font-family: "Inter-Bold";
  src: url(../assets/fonts/Inter-Bold.ttf);
}

@font-face {
  font-family: "Inter-ExtraBold";
  src: url(../assets/fonts/Inter-ExtraBold.ttf);
}

@font-face {
  font-family: "Inter-ExtraLight";
  src: url(../assets/fonts/Inter-ExtraLight.ttf);
}

@font-face {
  font-family: "Inter-Light";
  src: url(../assets/fonts/Inter-Light.ttf);
}

@font-face {
  font-family: "Inter-Medium";
  src: url(../assets/fonts/Inter-Medium.ttf);
}

@font-face {
  font-family: "Inter-Regular";
  src: url(../assets/fonts/Inter-Regular.ttf);
}

@font-face {
  font-family: "Inter-SemiBold";
  src: url(../assets/fonts/Inter-SemiBold.ttf);
}

@font-face {
  font-family: "Inter-Thin";
  src: url(../assets/fonts/Inter-Thin.ttf);
}

@font-face {
  font-family: "Lyon Regular";
  src: url(../assets/fonts/LyonRegular.ttf);
}

@font-face {
  font-family: "Lyon Regular Italic";
  src: url(../assets/fonts/Lyon-Italic.ttf);
}

@font-face {
  font-family: "PLayfairDisplay-Bold";
  src: url(../assets/fonts/PlayfairDisplay-Bold.ttf);
}

@font-face {
  font-family: "PLayfairDisplay-BoldItalic";
  src: url(../assets/fonts/PlayfairDisplay-BoldItalic.ttf);
}

@font-face {
  font-family: "PLayfairDisplay-ExtraBold";
  src: url(../assets/fonts/PlayfairDisplay-ExtraBold.ttf);
}

@font-face {
  font-family: "PLayfairDisplay-ExtraBoldItalic";
  src: url(../assets/fonts/PlayfairDisplay-ExtraBoldItalic.ttf);
}

@font-face {
  font-family: "PLayfairDisplay-Italic";
  src: url(../assets/fonts/PlayfairDisplay-Italic.ttf);
}

@font-face {
  font-family: "PLayfairDisplay-Medium";
  src: url(../assets/fonts/PlayfairDisplay-Medium.ttf);
}

@font-face {
  font-family: "PLayfairDisplay-MediumItalic";
  src: url(../assets/fonts/PlayfairDisplay-MediumItalic.ttf);
}

@font-face {
  font-family: "PLayfairDisplay-Regular";
  src: url(../assets/fonts/PlayfairDisplay-Regular.ttf);
}

@font-face {
  font-family: "PLayfairDisplay-SemiBold";
  src: url(../assets/fonts/PlayfairDisplay-SemiBold.ttf);
}

@font-face {
  font-family: "PLayfairDisplay-SemiBoldItalic";
  src: url(../assets/fonts/PlayfairDisplay-SemiBoldItalic.ttf);
}

@font-face {
  font-family: "Garamond";
  src: url(../assets/fonts/GARAIT_main.ttf);
}

@font-face {
  font-family: "Garamond Normal";
  src: url(../assets/fonts/GARABD1.ttf);
}

@font-face {
  font-family: "Garamond Bold";
  src: url(../assets/fonts/GARABD1.ttf);
}
