@import "../../styles/variables.scss";

.logo {
  display: flex;
  justify-content: center;
  margin-top: 36px;
}

.footer {
  position: absolute;
  bottom: 20px;
  width: 100%;
  padding-bottom: 10px;
  &__grid {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px 24px;
  }
}

.action {
  margin-top: 12px;
  background: none;
  border: none;
  outline: none;
  text-align: left;
  color: white;
  width: 100%;
}
.avatar {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 12px 12px 12px 0px;
  border-bottom: 1px solid #212121;
  &__img {
    margin-right: 12px;
  }
}

// Filter
.filterContainer {
  border-top: 2px solid #212121;

  & .filter {
    height: 63px;
    padding: 12px 36px;
    border-bottom: 2px solid #212121;
  }
}
