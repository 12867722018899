@import "./variables.scss";
@import "../../node_modules/bootstrap/scss/bootstrap";

.card {
  padding: 24px;
}

main {
  width: 100%;
}

.tableAvatar {
  display: flex;
  width: 100%;
  align-items: center;

  &__img {
    margin-right: 12px;
    width: 50px;
    height: 50px;

    & > img {
      border-radius: 50%;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
}

.borderImg {
  border: 3px solid #ffae00;
}

a {
  color: $color-white !important;
  text-decoration: none !important;

  &:hover {
    text-decoration: none !important;
    color: $color-secondary !important;
  }
}

// Scroll Bar
::-webkit-scrollbar {
  width: 8px;
  height: 7px;
}

::-webkit-scrollbar-track {
  background-color: $color-background;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $color-white;
  border-radius: 10px;
}

/* Change the input focus to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-text-fill-color: white !important;
  box-shadow: 0 0 0 30px #212121 inset !important;
  -webkit-box-shadow: 0 0 0 30px #212121 inset !important;
}

.text-ellipsis {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}


.MuiPaper-root > div > div {
  height: auto;
}