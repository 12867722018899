.winners__List {
  margin-bottom: 12px;
}

.WinnerImg {
  width: 24px;
  height: 24px;
  margin-right: 12px;

  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
}
