.Main-Container {
    background: #1C1F21;
    color: white;
    min-height: 100vh;
}

.header-con {
    height: 40vh;
    background-image: url("../../assets/icons//ellipse.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    margin-bottom: 50px;
}

@media screen and (max-width: 1440px) and (min-width: 1200px) {
    .header-con {
        height: 50vh;
    }
}

@media screen and (max-width: 2000px) and (min-width: 1440px) {
    .header-con {
        height: 40vh;
    }
}

@media screen and (max-width: 2560px) and (min-width: 2000px) {
    .header-con {
        height: 25vh;
    }
}

.logo-con {
    padding-top: 80px;
    padding-bottom: 40px;
}

.logo-con>img {
    width: 300px;
}

.linkCon {
    white-space: pre-wrap;
    word-wrap: break-word;
}

.linkCon>a {
    color: #4FACE9 !important;
    font-size: 18px;
    text-decoration: none !important;
}

.credential {
    color: #4FACE9 !important;
}

.poweredbyCon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 40px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.poweredbyCon>img {
    width: 100px;
    margin-right: 20px;
}