@import "../../../../../../styles/variables.scss";

.container {
  width: 100%;
  overflow: hidden;
  margin-top: -2px;
}

.header {
  opacity: 0.6;
  position: relative;
  text-align: center;
}

.correctAnswerText {
  color: theme-color(secondary);
  text-decoration: underline;
  text-underline-offset: 2px;
  cursor: pointer;
}

.serialNo {
  border-width: 2px;
  border-style: solid;
  border-radius: 6px;
  padding: 12px 10px;
  width: fit-content;
  border-color: theme-color(mainWhite);
}

.questionCtr {
  border-radius: 12px;
  padding: 24px;
  padding-left: 27px;
  background-color: theme-color(background-color-default) !important;
  display: flex;
  justify-content: space-between;
}

.question_added {
  border-radius: 8px;
  border: 1px solid theme-color(secondary);
}

.expandableRow {
  padding: 0px !important;
  border: none !important;
}

.question {
  display: flex;
  flex-direction: column;
}

.questionHeader {
  text-transform: uppercase;
}

.questionTitle {
  display: flex;
  align-items: center;
  margin-bottom: 2px;
  height: 45px;
}

.option {
  margin-left: 52px;
  display: flex;
  align-items: center;
  margin-top: 12px;

  & .optionNumber {
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
  }
}

.optionCorrect {
  & .optionNumber {
    background-color: theme-color(mainGreen);
    color: theme-color(background-color);
  }
  & .optionName {
    color: theme-color(mainGreen);
  }
}

.avatar {
  height: 36px;
  width: 36px;
  border-radius: 50%;
  margin: 16px 0px 10px;
}

.suggestedBy {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.borderBottomNone {
  border-bottom: none !important;
}

.questionBankCtr {
  border-radius: 12px;
  padding: 24px;
  padding-left: 27px;
  background-color: theme-color(background-color-default);
  display: flex;
}

.indexBorder {
  width: 29.05px;
  height: 44px;
  border-radius: 6px;
  border: 2px solid;
  display: flex;
  justify-content: center;
  align-items: center;
}

.question_bank_option {
  display: flex;
  align-items: center;
  margin-top: 12px;

  & .optionNumber {
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
  }
}

.questiondiv {
  display: flex;
  justify-content: space-between;
}

.countBorder {
  padding: 0px 10px;
  height: 44px;
  border-radius: 6px;
  border: 2px solid;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inUse {
  border-radius: 6px;
  border: 2px solid theme-color(secondary);
  background: theme-color(secondary);
  color: initial;
  display: flex;
  width: 81px;
  padding: 4px 0px;
  justify-content: center;
  align-items: center;
}

.ellipse_gold {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: theme-color(secondary);
  position: absolute;
  z-index: 10;
  right: -10px;
  top: -10px !important;
  align-items: center;
  display: flex;
  justify-content: center;
  cursor: pointer;
  color: black;
}

.incorrect_analytics {
  opacity: 1 !important;
  color: theme-color(mainRed) !important;
  border: 1px solid theme-color(mainRed);
  border-radius: 2px;
  padding: 2px 4px 2px 0px;
  margin-left: 16px !important;
}

.rank {
  width: 32px;
  height: 32px;
  padding: 10px 12px 10px 12px;
  border-radius: 4px;
  background: theme-color(primary);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.rank_selected {
  width: 32px;
  height: 32px;
  padding: 10px 12px 10px 12px;
  border-radius: 4px;
  background: $linear-gradient;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
