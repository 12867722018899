@import "../../../../../styles/variables.scss";

.container {
  display: flex;
  flex-direction: column !important;
  align-items: center;
  justify-content: center;
  min-width: 90px;
  background-color: theme-color(primary);
  border-radius: 4px;
  margin: 24px 16px 16px 0px !important;
  min-height: 50px;
}

.cat_container {
  background-color: theme-color(secondary);
  border-radius: 0px 0px 4px 4px;
  justify-content: center;
  align-items: center;
  height: 20px;
}

.ellipse_spirit_gold {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: theme-color(secondary);
  position: absolute;
  z-index: 10;
  right: 5px;
  top: 10px;
  font-family: "Inter-Regular", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 12px;
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 4px;
}

.withBoxShadow {
  box-shadow: 5px 5px 10px 7px rgba(173, 145, 111, 0.4);
  border: 2px solid #ad916f;
  border-radius: 5px;
}
