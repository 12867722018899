@import "../../styles/variables.scss";

.imageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: theme-color(background-color-default);
  border-radius: 12px;
  flex-direction: column;
}

.loadingImage {
  animation: upDown 2s ease infinite;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: theme-color(background-color-default);
  border-radius: 12px;
  flex-direction: column;
}
img {
  width: "100%";
  height: "auto";
}

@keyframes upDown {
  0% {
    transform: translateY(-10%);
  }

  50% {
    transform: translateY(10%);
  }

  100% {
    transform: translateY(-10%);
  }
}
