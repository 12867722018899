@import "../../styles/variables.scss";

.Container {
    background: $color-primary;
    color: white;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.logo{
    &>img{
        width: 30%;
    }
}