.gaugeChart {
  position: relative;
  display: flex;
  justify-content: center;
  & > canvas {
    height: 300px !important;

    // aspect-ratio: 0 !important;
  }
}

.gaugeChartData {
  position: absolute;
  display: flex;
  flex-direction: column;
  text-align: center;
  bottom: 25%;
  left: 50%;
  transform: translate(-50%, 50%);
  & > div {
    & > span {
      line-height: 30px;
    }
  }
}
