.shimmerLoader {
  background: #3A312C
    linear-gradient(to left, #3A312C 0%, #61524a 20%, #3A312C 40%, #3A312C 100%)
    no-repeat;
  background-size: 1000px 1000px;
  display: inline-block;
  transform: rotate(180);
  position: relative;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shimmerAnimation;
  animation-timing-function: linear;
  margin: 12px;
}

@keyframes shimmerAnimation {
  0% {
    background-position: -1000px 0;
  }

  100% {
    background-position: 1000px 0;
  }
}

@-webkit-keyframes shimmerAnimation {
  0% {
    background-position: -1000px 0;
  }

  100% {
    background-position: 1000px 0;
  }
}
