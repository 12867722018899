.imgBox {
    position: absolute;
    bottom: 0px;
    margin-right: 30px;
    width: 40px;
    height: 100px;

    &>img {
        object-fit: contain;
        height: 100%;
        width: 100%;
    }
}

.headingContainer {
    margin-left: 50px;
}

.iconImg {
    width: 14px;
    margin-right: 10px;
}

.columnGrid {
    display: grid;
    grid-template-columns: 0.8fr 1fr;
    margin-top: 30px !important;
}

.imgContainer {
    width: 120px;
    height: 120px;
    margin-right: 12px;

    &>img {
        object-fit: cover;
        border-radius: 6px;
        height: 100%;
        width: 100%;
    }
}