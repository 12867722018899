@import "../../styles/variables.scss";

.filterContainer div .MuiPickerStaticWrapper-root {
  min-width: 230px;
  border-radius: 12px;
}

.pickerContainer .MuiPickerStaticWrapper-root .css-1xhj18k {
  flex-direction: column;
}

// Year pickers

.pickerContainer .MuiPickerStaticWrapper-root div div .MuiCalendarPicker-root div .MuiYearPicker-root {
  width: 225px;
  height: 200px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.pickerContainer .MuiPickerStaticWrapper-root div div .MuiCalendarPicker-root div .MuiYearPicker-root .Mui-selected {
  background: none;
  color: theme-color(secondary);
}

.pickerContainer .MuiPickerStaticWrapper-root div div .MuiCalendarPicker-root div .MuiYearPicker-root .MuiTypography-root {
  font-family: typography(fontSize16);
}

// Month Pickers
.pickerContainer .MuiPickerStaticWrapper-root div div .MuiCalendarPicker-root div .MuiMonthPicker-root {
  width: 225px;
  height: 200px;
}

.pickerContainer .MuiPickerStaticWrapper-root div div .MuiCalendarPicker-root div .MuiMonthPicker-root .Mui-selected {
  background: none;
  color: theme-color(secondary);
}

.pickerContainer .MuiPickerStaticWrapper-root div div .MuiCalendarPicker-root div .MuiMonthPicker-root .Mui-selected {
  font-family: typography(fontSize16);
  background: none;
  color: theme-color(secondary);
}

.pickerContainer .MuiPickerStaticWrapper-root div div .MuiCalendarPicker-root div .MuiMonthPicker-root .MuiTypography-root {
  font-family: typography(fontSize16);
}

// Hides calendar header
.pickerContainer .MuiPickerStaticWrapper-root div div .MuiCalendarPicker-root .css-1dozdou {
  display: none;
}

.pickerContainer .MuiPickerStaticWrapper-root div div .MuiCalendarPicker-root {
  width: 100% !important;
  min-width: none !important;
}

// Month view height auto
.pickerContainer .MuiPickerStaticWrapper-root div .css-epd502 {
  height: 200px;
}

.pickerContainer {
  margin: 0;
  padding: 0;
  padding-bottom: 40px;
  position: absolute;
  left: 3px;
  border-radius: 12px;
  background-color: $color-background-default;
  z-index: 1;
}

.datePicker {
  position: fixed;
}

.datePicker .MuiPickerStaticWrapper-root div .css-epd502 {
  height: 270px;
}

.datePicker .MuiPickerStaticWrapper-root div div .MuiCalendarPicker-root div .MuiYearPicker-root {
  height: 270px;
}

.datePicker .MuiPickerStaticWrapper-root div div .MuiCalendarPicker-root div .MuiMonthPicker-root {
  height: 270px;
}

.datePicker .css-n0fpkf {
  display: none;
}
.datePicker .MuiCalendarPicker-viewTransitionContainer{
  margin-top: 12px;
}

.bottomButtonContainer {
  position: absolute;
  bottom: 10px;
  right: 12px;
}