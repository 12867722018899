$color-primary: #231e1b;
$color-white: #ffffff;
$color-white-secondary: #c4c4c4;
$color-background: #120e0c;
$color-background-default: #1d1916;
$color-secondary: #ad916f;
$color-calendar-event: rgba(204, 75, 55, 0.15);
$color-success: rgba(156, 196, 59, 0.5);
$color-negative: rgba(204, 75, 55, 0.3);
$color-red: #cc4b37;
$color-green: #3adb76;
$color-aqua: #4face9;
$linear-gradient: linear-gradient(180deg, #675950 0%, #46372f 100%);
$color-light-background-default: #3a312c;
$color-orange: #ffae00;
$color-red-sec: #db443a;
$color-sec_aqua: #274c9a;

$theme-colors: (
  primary: $color-primary,
  secondary: $color-secondary,
  mainWhite: $color-white,
  background-color: $color-background,
  colorLightBackgroundDefault: $color-light-background-default,
  background-color-default: $color-background-default,
  calendar-event: $color-calendar-event,
  success: $color-success,
  secondaryWhite: $color-white-secondary,
  mainGreen: $color-green,
  mainRed: $color-red,
  mainAqua: $color-aqua,
  mainOrange: $color-orange,
  secRed: $color-red-sec,
  secondaryAqua: $color-sec_aqua
);

@function theme-color($key) {
  @return map-get($theme-colors, $key);
}

$inter-regular: "Inter-Regular";
$lato-regular: "Lato-Regular";
$PLayfairDisplay-Regular: "PLayfairDisplay-Regular";
$PLayfairDisplay-Italic: "PLayfairDisplay-Italic";
$bold400: 400;
$fontSize14: 14px;
$fontSize12: 12px;
$fontSize8: 8px;

$typography: (
  interRegular: $inter-regular,
  latoRegular: $lato-regular,
  playfairRegular: $PLayfairDisplay-Regular,
  playfairItalic: $PLayfairDisplay-Italic,
  fontWeight400: $bold400,
  fontSize14: $fontSize14,
  fontSize12: $fontSize12,
  fontSize8: $fontSize8
);

@function typography($key) {
  @return map-get($typography, $key);
}
