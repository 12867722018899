@import "../../styles/variables.scss";

.itemBox {
    display: grid;
    grid-template-columns: 0.4fr 1fr;
    border-radius: 6px;
    cursor: pointer;
}

.itemBox:hover {
    background-color: theme-color(colorLightBackgroundDefault);
}

.itemIsActive {
    background-color: theme-color(colorLightBackgroundDefault) !important;
}

.wineBox {
    height: 80px;
    min-width: 100px;
    border-radius: 8px;
    display: flex;
    margin-right: 10px;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(196, 196, 196, 0.2);
    padding: 5px 0px;
    &>img {
        // width: fit-content;
        height: 100% !important;
        // object-fit: cover;
    }

}

.textOverflow {
    display: -webkit-box;
    max-width: 90%;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}