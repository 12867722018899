@import "../../styles/variables.scss";

.phoneContainerBox .react-tel-input .special-label {
    display: none;
}
.phoneContainerBox .react-tel-input :disabled
{
    background-color: $color-background-default !important;
}
.phoneContainerBox .react-tel-input .form-control {
    height: 50px;
    border-radius: 6px;
    background-color: $color-background;
    transition: 0.2s;
    color: $color-white;
    width: 100% !important;
    padding-left: 60px !important;
}

.phoneContainerBox .react-tel-input .form-control:focus {
    height: 50px;
    background-color: $color-background-default !important;
    border-color: $color-white !important;
    box-shadow: none !important;
    color: $color-white;
}

.phoneContainerBox .react-tel-input .form-control:hover {
    background-color: $color-background-default !important;
}

.phoneContainerBox .react-tel-input .flag-dropdown {
    background-color: $color-background
}

.phoneContainerBox .react-tel-input .flag-dropdown .selected-flag {
    transition: 0.2s;
    width: 50px;
    padding: 0 0 0 14px
}

.phoneContainerBox .react-tel-input .flag-dropdown.open .selected-flag{
    background-color: $color-background-default
}

.phoneContainerBox .react-tel-input .flag-dropdown .selected-flag:hover {
    background-color: $color-background-default
}

.phoneContainerBox .react-tel-input .flag-dropdown .selected-flag:hover {
    background-color: $color-background-default
}


.phoneContainerBox .react-tel-input .flag-dropdown .selected-flag:focus {
    background: $color-background-default;
}

.phoneContainerBox .react-tel-input .country-list{
    background-color:$color-background-default;
    border-radius: 6px;
}

.phoneContainerBox .react-tel-input .country-list .country:hover{
    background-color: $color-primary;
}

.phoneContainerBox .react-tel-input .country-list .country.highlight{
    background-color: $color-primary;
}