@import "../../../../styles//variables.scss";

.menuContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 10px;
  padding: 0;
  column-gap: 30px;
}

.itemContainer {
  display: grid;
  grid-template-columns: 1fr 1.9fr 0.1fr;
  margin: 24px 0 24px 0;
  border-bottom: 0.125rem solid theme-color(primary);
}

.archivedItemContainer {
  display: grid;
  grid-template-columns: 0.3fr 1fr 1.9fr 0.1fr;
  margin: 24px 0 24px 0;
  border-bottom: 0.125rem solid theme-color(primary);
}

.triviaItems {
  display: grid;
  grid-template-columns: 0.7fr 1.9fr 4fr;
  margin: 24px 0 24px 0;
  border-bottom: 0.125rem solid theme-color(primary);
}

.selectedTriviaItems {
  display: grid;
  grid-template-columns: 0.5fr 1fr 0.5fr;
  margin: 12px 16px 12px 16px;
  border-bottom: 0.125rem solid theme-color(primary);
}

.imageContainer {
  width: 200px;
  height: 200px;
  margin-right: 12px;

  & > img {
    width: 100%;
    border-radius: 12px;
    height: 100%;
    object-fit: cover;
  }
}

.ellipse {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: theme-color(mainRed);
  position: absolute;
  z-index: 10;
  right: -3px;
  top: -3px;
}

.ellipse_gold {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-color: theme-color(secondary);
  position: absolute;
  z-index: 10;
  right: -10px;
  top: -10px;
  border: 2px solid theme-color(background-color);
  font-family: "Inter Regular", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 12px;
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 4px;
}

.ellipse_spirit_gold {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-color: theme-color(secondary);
  position: absolute;
  z-index: 10;
  right: 5px;
  top: -9px;
  border: 2px solid theme-color(background-color);
  font-family: "Inter-Regular", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 12px;
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 4px;
}

.new_tag {
  border-radius: 4px;
  font-family: "Inter-Regular", sans-serif;
  font-weight: 500;
  font-size: 12px;
  background-color: theme-color(secondary);
  padding: 4px 6px;
  margin-left: 6px;
}

.spiritContainer {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 10px;
  padding: 0;
  column-gap: 30px;
}

.text {
  font-family: "Inter-Regular", sans-serif;
  color: theme-color(secondary);
  font-weight: 500;
  font-size: 14px;
}

.itemContainer_1 {
  display: grid;
  grid-template-columns: 1fr 1.9fr;
  gap: 14px;
  margin: 24px 0 24px 0;
  padding: 16px;
  border-radius: 12px;
  border: 1px solid #ad916f;
  background-color: #231e1b;
}

.view {
  font-size: 28px;
  cursor: pointer;
  border: 2px solid #ad916f;
  border-radius: 16px;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 6px 6px;
  color: theme-color(secondary);
}

.spirit_count {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: theme-color(secondary);
  position: absolute;
  z-index: 10;
  right: -10px;
  top: -9px;
  border: 2px solid theme-color(background-color);
  font-family: "Inter-Regular", sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 12px;
  align-items: center;
  display: flex;
  justify-content: center;
  color: #fff;
}

.total_count {
  background-color: theme-color(secondary);
  border: 2px solid theme-color(background-color);
  border-radius: 8px;
  font-family: "Inter-Regular", sans-serif;
  font-size: 14px;
  font-weight: 500;
  padding: 6px 8px;
  margin-right: 20px;
}
