.iconCounter {
  padding: 0px 25px;
  display: flex;
  align-items: center;
  text-align: center;
}
.inputContainer {
  display: flex;
  background-color: #120E0C;
  padding: 4px 0px;
  border-radius: 6px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
