.container {
  display: flex;
  flex-direction: column;

  & .TitleCtr {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  & .calendarCtr {
    width: 100%;
    box-sizing: border-box;
    padding: 20px 5px;
    border: 1px solid rgba(135, 135, 135, 0.3);
    border-radius: 8px;
    margin-top: 12px;
  }
}

.pickerDateCtr {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 4px;
  justify-content: flex-end;
  & .calendarDateText {
    font-size: 10px;
  }
  & .event {
    background: transparent;
    height: 4px;
    width: 4px;
    border-radius: 50%;
    margin-bottom: 4px;

    &.holiday {
      background: #e67c1b;
    }
  }
  & .readOnlyDateCtr {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border: 0.5px solid transparent;
    border-radius: 4px;
    position: relative;
    & .eventDot {
      height: 4px;
      width: 4px;
      box-sizing: content-box;
      position: absolute;
      background: transparent;
      top: -4px;
      border: 2px solid transparent;
      border-radius: 50%;
    }
    &.readOnlyEvent {
      border-color: #4face9;

      & .eventDot {
        background: #e67c1b;
        border-color: #1c1f21;
      }
    }
  }
}

.holidaysCtr {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 20px;

  & .holidayCtr {
    display: flex;
    gap: 12px;

    & .dateCtr {
      display: flex;
      flex-direction: column;
      min-width: 24px;
    }

    & .holidayNameCard {
      background: #252729;
      border-radius: 6px;
      overflow: hidden;
      padding: 8px 18px;
      flex-grow: 1;
      flex-shrink: 1;

      & span {
        color: #c4c4c4;
      }
    }
  }
}
