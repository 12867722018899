@import "../../../styles/variables.scss";

.MuiPickerStaticWrapper-root {
  background-color: $color-background-default !important;
}

.MuiPickersDay-today {
  background-color: $color-secondary !important;
}

.PrivatePickersSlideTransition-root {
  min-height: 264px !important;
}

.css-mvmu1r div .MuiButtonBase-root {
  background-color: transparent !important;
  font-size: 14px !important;
  font-weight: 400;
  font-family: typography(interRegular);
}

.css-mvmu1r div .Mui-selected {
  background: $linear-gradient !important;
}

.col-lg-12
  div
  > .MuiPickerStaticWrapper-root
  div
  div
  .MuiCalendarPicker-root
  .PrivatePickersFadeTransitionGroup-root
  div
  .PrivatePickersSlideTransition-root {
  overflow-y: visible !important;
  overflow-x: visible !important;
}
.css-epd502 {
  width: 100% !important;
  height: 600px;
}
.calendarCtr .css-epd502 .MuiCalendarPicker-root {
  width: 100% !important;
  background-color: $color-primary !important;
  max-height: 400px !important;
  max-width: 100% !important;
}

.css-1n2mv2k {
  justify-content: space-around !important;
}

.css-mvmu1r {
  justify-content: space-around !important;
  margin-bottom: 5px;
}

.MuiPickersArrowSwitcher-root button .MuiSvgIcon-root {
  fill: white !important;
}

.col-lg-12 .css-1470ar6 div div div div .css-1dozdou {
  display: flex !important;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  max-width: 50%;
  margin-top: 8px !important;
  margin-left: 13px;
  min-height: fit-content !important;
  max-height: fit-content !important;
}

.col-lg-12 div div div div div .css-1dozdou .css-l0iinn {
  pointer-events: none;
}

.col-lg-12 div div div div div .css-1dozdou div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: initial;
}

.col-lg-12 div div div div div .css-1dozdou div button {
  display: none;
}

.PrivatePickersFadeTransitionGroup-root {
  font-family: typography(playfairRegular);
  font-weight: 400;
  font-size: 24px;
}

.MuiCalendarPicker-viewTransitionContainer {
  overflow-y: hidden !important;
}

.MuiPickersArrowSwitcher-root {
  position: relative;
  top: 5px;
}

.css-l0iinn button {
  display: none;
}

.css-l0iinn {
  width: fit-content;
}

.col-lg-12 div div div div div .css-1dozdou .MuiPickersArrowSwitcher-root div {
  display: none;
}

@media only screen and (max-width: 1920px) {
  .col-lg-12 .css-1470ar6 div div div div .css-1dozdou {
    max-width: 45%;
  }
}

@media only screen and (max-width: 1505px) {
  .col-lg-12 .css-1470ar6 div div div div .css-1dozdou {
    max-width: 57%;
  }
}

@media only screen and (max-width: 1366px) {
  .col-lg-12 .css-1470ar6 div div div div .css-1dozdou {
    max-width: 57%;
  }
}


.MuiPickerStaticWrapper-root{
  min-width: 250px !important;
}
