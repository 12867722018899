@import "../../../../styles/variables.scss";

.dialogBox {
  background-color: theme-color(background-color-default);
  border-radius: 12px;
  border: none;
  width: 740px;
  padding: 0px !important;
  box-sizing: border-box;
}

.closeButton {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  background-color: theme-color(background-color);
  position: absolute;
  top: 24px;
  right: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.uploadCtrOuter {
  padding-bottom: 30px;
}

.uploadCtr {
  margin: 67px 35px 5px;
  height: 212px;
  position: relative;
}

.borderImage {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.innerUploadCtr {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  & img {
    margin-top: 24px;
    margin-bottom: 14px;
  }

  & .imgUploadHelperText {
    margin-top: 10px;
    margin-bottom: 14px;
  }

  & .browseBtn {
    padding: 12px 69px;
    border-color: #4face9;
  }
}

.imageListItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 21px 24px;
  border-radius: 8px;
  background-color: theme-color(background-color);
}

.imageItemInnerCtr {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.deleteBtn {
  cursor: pointer;
  margin-right: 6px;
  margin-left: 10px;
}

.divider {
  width: 100%;
  height: 2px;
  background-color: theme-color(background-color);
  margin-bottom: 35px;
}

.uploadBtn {
  padding: 12px 79px !important;
  margin-bottom: 35px !important;
}

.tabNavigationCtr {
  width: 100%;
  border-bottom: 1px solid #000000;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.navItem {
  padding-bottom: 10px;
  margin: 0px 50px;
  width: 102px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-bottom: 2px solid transparent;
}

.navItemActive {
  border-bottom: 2px solid #4face9;
}
