@import "../../styles/variables.scss";

// Scrollers
.fc .fc-scroller-harness {
  overflow: auto;
}

.fc-scroller-liquid-absolute {
  overflow: auto !important;
}

// table Cell sizes

.fc .fc-daygrid-day-frame {
  border: 0.5px solid $color-primary;
  min-width: 7vw;
  height: 30vh !important;
}

.fc-theme-standard td {
  border-style: hidden !important;
  height: 23vh !important;
}

// Cell top
.fc-col-header-cell-cushion {
  color: $color-white;
  text-decoration: none;
  font-size: $fontSize14;
  font-weight: $bold400;
  margin-bottom: 16px;
  margin-right: 5px;
}

.fc-daygrid-day-number {
  text-decoration: none;
  padding: 4px 8px;
}

.date {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-family: $inter-regular;
  font-size: $fontSize12;
  margin: 7px 10px 7px 10px;
  border-radius: 100%;
  width: 22px;
  height: 22px;
  text-align: center;
  color: #fff;
  cursor: default;
}

.positive-date {
  background-color: $color-primary;
}

.negative-date {
  background-color: $color-negative;
}

.wine-date {
  background-color: $color-secondary;
}

.fc-scroller {
  overflow: auto !important;
}

.fc-scrollgrid-sync-inner {
  text-align: right;
  color: $color-white;
}

.fc-scrollgrid {
  border-style: hidden !important;
}

.fc-theme-standard th {
  border-style: hidden !important;
}

.fc-h-event {
  border-style: none !important;
  text-decoration: none !important;
  cursor: auto !important;
}

.fc-daygrid-day-events {
  position: none !important;
  visibility: hidden !important;
}

.fc-event-main {
  visibility: visible !important;
  text-decoration: none !important;
}

.event-content {
  background: transparent !important;
  margin: 0 10px 0 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 19vh;
  text-decoration: none !important;
}

.event-itself {
  border-radius: 2px;
  text-align: left;
  display: flex;
  align-items: center;
}

.event-icon {
  margin: 7px 5px;
  opacity: 1;
}

.day-icon-tray {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 5px 0 24px 0;
}

.icon-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  height: 32px;
  margin-right: 28px;
}

.icon {
  height: 12px;
  margin-bottom: 4px;
  opacity: 1;
}

.greenBg {
  background-color: $color-success;
}

.orangeBg {
  background: $linear-gradient;
}

.fc-daygrid-day-frame .fc-bg-event {
  z-index: 1;
}

.fc-bg-event .event-content {
  background: $linear-gradient;
  margin: 44px 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 19vh;
  z-index: 2;
}

.fc .fc-daygrid-day-bg .fc-highlight {
  z-index: 0 !important;
}

.fc-bg-event {
  opacity: 1 !important;
}

.fc .fc-toolbar.fc-header-toolbar {
  display: none !important;
}

.fc .fc-daygrid-day.fc-day-today {
  background: none !important;
}

.fc .fc-daygrid-day.fc-day-today .fc-daygrid-day-frame {
  border: 2px solid $color-secondary;
}

.popover-container {
  height: 20px;
  width: 100px;
  margin: 10px;
  -o-border-radius: 4px;
  -ms-border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  position: relative;
  top: -29px;
  left: -12px;
  background-color: #545454;
  text-decoration: none !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.popover-container:after {
  content: " ";
  border-bottom: 5px solid #545454;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  position: relative;
  top: -12px;
  right: 70px;
  text-decoration: none !important;
}

a:hover {
  text-decoration: none !important;
}

@media only screen and (max-width: 1024px) {
  .event-content {
    margin: 0 5px;
    height: 17vh;
    display: flex;
    justify-content: center;
    align-items: space-between;
  }

  .fc-scrollgrid thead {
    display: none;
  }

  .fc-scroller {
    overflow: scroll;
    overflow-y: scroll;
  }

  .fc-bg-event .event-content {
    height: 17vh;
  }

  .fc .fc-daygrid-day-frame {
    width: 13.5vw;
  }

  .fc-theme-standard td {
    width: 13.5vw;
  }
}

@media only screen and (min-width: 1366px) and (max-width: 1599px) {
  .fc-scroller {
    overflow-y: hidden !important;
  }

  .fc-view-harness {
    height: 1150px !important;
  }
}

@media only screen and (min-width: 1600px) and (max-width: 1919px) {
  .event-content {
    height: 23vh;
  }

  .fc-bg-event .event-content {
    height: 23vh;
  }

  .fc-view-harness {
    height: 1300px !important;
  }
}

@media only screen and (min-width: 1920px) {
  .event-content {
    height: 26vh;
  }

  .fc-bg-event .event-content {
    height: 26vh;
  }

  .fc-view-harness {
    height: 1450px !important;
  }

  .popover-container {
    top: -60px;
  }
}