@import "../../styles/variables.scss";

.notificationBox {
  padding: 14px;
  border: 2px solid $color-primary;
  border-radius: 6px;
  cursor: pointer;
  margin-right: 20px;
}

.bellBox {
  width: 24px;
  height: 24px;
  position: relative;
  & > img {
    position: relative;
    width: 100%;
  }
}

.bellAlert {
  position: absolute;
  width: 6px;
  height: 6px;
  background-color: $color-red;
  border-radius: 50%;
}

.questionCount {
  padding: 10px;
  font-family: "Inter-Medium", sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  color: #fff;
  background-color: $color-secondary;
  border-radius: 40px;
  vertical-align: middle;
  margin-left: 24px;
}
