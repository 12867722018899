@import "../../styles/variables.scss";

.answerBox {
  margin-left: 2.5rem;
  margin-top: 1.5rem;
}
.option {
  display: flex;
  align-items: center;
  margin: 15px;
  color: theme-color(mainWhite);

  & .optionNumber {
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    color: theme-color(mainWhite);
  }
}

.optionCorrect {
  & .optionNumber {
    background-color: theme-color(mainGreen);
    color: theme-color(background-color);
  }
  & .optionName {
    color: theme-color(mainGreen);
  }
}
