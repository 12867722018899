.tableAvatar {
  display: flex;
  width: 100%;
  align-items: center;

  &__img {
    margin-right: 12px;


    &>img {
      border-radius: 50%;
      object-fit: cover;
      width: 50px;
      height: 50px;
    }
  }
}

.borderImg {

  border: 3px solid #ffae00;
  border-radius: 100%;
}

.columnIcon {
  margin-left: 10px;
  cursor: pointer;
}