.tableAvatar {
    display: flex;
    width: 100%;
    align-items: center;

    &__img {
        margin-right: 24px;
   

        &>img {
            border-radius: 50%;
            width: 50px;
            height: 50px;
            object-fit: cover;
        }
    }
}

.rank {
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .status{
    border: 1px solid white;
    padding: 4px 10px;
    display: inline-block;
    align-items: center;
    border-radius: 6px;
  }