@import "../../../../styles/variables.scss";

.heading {
  padding: 18px;
  display: flex;
  justify-content: center;
}

.gaugeBackground {
  margin-bottom: -12px;
}
