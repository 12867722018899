@import "../../../../../../styles/variables.scss";

.container {
  width: 100%;
  overflow: hidden;
  margin-top: -2px;
}

.header {
  opacity: 0.6;
  position: relative;
  text-align: center;
}

.analyticsIcon {
  border-bottom: 2.5px solid theme-color(mainAqua);
  cursor: pointer;
}

.checkBoxCell {
  height: 24px;
  width: 24px;
}

.actionBtnCtr:hover {
  & svg {
    color: theme-color(mainAqua);
  }
  cursor: pointer;
}
