@import "../../styles/variables.scss";

.container {
  width: 100%;
  overflow: hidden;
  margin-top: -2px;
}

.header {
  opacity: 0.6;
  position: relative;
  text-align: center;
}

.correctAnswerText {
  color: theme-color(secondary);
  text-decoration: underline;
  text-underline-offset: 2px;
  cursor: pointer;
}

.serialNo {
  border-width: 2px;
  border-style: solid;
  border-radius: 6px;
  padding: 12px 10px;
  width: fit-content;
  border-color: theme-color(mainWhite);
}

.questionCtr {
  border-radius: 12px;
  padding: 24px;
  padding-left: 27px;
  background-color: theme-color(background-color-default);
  display: flex;
  justify-content: space-between;
}

.expandableRow {
  padding: 0px !important;
  border: none !important;
}

.question {
  display: flex;
  flex-direction: column;
}

.questionHeader {
  text-transform: uppercase;
}

.questionTitle {
  display: flex;
  align-items: center;
  margin-bottom: 2px;
  height: 36px;
}

.option {
  margin-left: 52px;
  display: flex;
  align-items: center;
  margin-top: 12px;

  & .optionNumber {
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
  }
}

.optionCorrect {
  & .optionNumber {
    background-color: theme-color(mainGreen);
    color: theme-color(background-color);
  }
  & .optionName {
    color: theme-color(mainGreen);
  }
}

.avatar {
  height: 36px;
  width: 36px;
  border-radius: 50%;
  margin: 16px 0px 10px;
}

.suggestedBy {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.borderBottomNone {
  border-bottom: none !important;
}
