@import "../../../../../styles/variables.scss";

.closeButton {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  background-color: theme-color(background-color);
  position: absolute;
  top: 24px;
  right: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.uploadCtr {
  height: 180px;
  position: relative;
}

.borderImage {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}

.innerUploadCtr {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  & img {
    margin-top: 12px;
    margin-bottom: 12px;
  }

  & .imgUploadHelperText {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  & .browseBtn {
    margin-top: 8px;
    padding: 6px 50px;
  }
}

.imageListItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  padding: 18px 14px;
  border-radius: 8px;
  background-color: theme-color(background-color);
}

.imageItemInnerCtr {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.deleteBtn {
  cursor: pointer;
  margin-right: 6px;
  margin-left: 10px;
}

.divider {
  width: 100%;
  height: 2px;
  background-color: theme-color(background-color);
  margin-bottom: 35px;
}
