.container {
  width: 100%;
  overflow: hidden;
  margin-top: -2px;
}

.header {
  opacity: 0.6;
  position: relative;
  text-align: center;
}

.correctAnswerText {
  color: #ad916f;
  text-decoration: underline;
  text-underline-offset: 2px;
  cursor: pointer;
}

.rank {
  border-width: 2px;
  border-style: solid;
  border-radius: 6px;
  padding: 12px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  border-color: #ad916f;
}
