@import "../../../../styles//variables.scss";

.menuContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 10px;
  padding: 0;
  column-gap: 30px;
}

.text {
  font-family: "Inter-Regular", sans-serif;
  color: theme-color(secondaryAqua);
  font-weight: 500;
  font-size: 14px;
}

.itemContainer {
  display: grid;
  grid-template-columns: 1fr 1.9fr;
  gap: 14px;
  margin: 24px 0 24px 0;
  padding: 16px;
  border-radius: 12px;
  border: 1px solid #1c3a8c;
  background-color: #15181a;
}

.imageContainer {
  width: 200px;
  height: 200px;
  margin-right: 12px;

  & > img {
    width: 100%;
    border-radius: 12px;
    height: 100%;
    object-fit: cover;
  }
}

.view {
  font-size: 28px;
  cursor: pointer;
  border: 2px solid #1c3a8c;
  border-radius: 16px;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 6px 6px;
  color: theme-color(secondaryAqua);
}

.spirit_count {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #1c3a8c;
  position: absolute;
  z-index: 10;
  right: -10px;
  top: -9px;
  border: 2px solid theme-color(background-color);
  font-family: "Inter-Regular", sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 12px;
  align-items: center;
  display: flex;
  justify-content: center;
  color: #fff;
}

.total_count {
  background-color: #1c3a8c;
  border: 2px solid theme-color(background-color);
  border-radius: 8px;
  font-family: "Inter-Regular", sans-serif;
  font-size: 14px;
  font-weight: 500;
  padding: 6px 8px;
  margin-right: 20px;
}
