.tableAvatar {
  display: flex;
  width: 100%;
  align-items: center;

  &__img {
    margin-right: 24px;
    width: 48px;
    height: auto;

    & > img {
      border-radius: 50%;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
}

.rank {
  width: 29.05px;
  height: 44px;
  border: 2px solid #3adb76;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}
