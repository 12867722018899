@import "../../styles/variables.scss";

.DateRangePicker {
  display: flex;
  background: theme-color(background-color-default);
  width: 100%;
  border: 1px solid;
  border-radius: 8px;
  height: 48px;
  align-items: center;
}

.DateRangePickerInput {
  display: flex;
  align-items: center;
  justify-content: center;
}

.DateRangePickerInput__withBorder {
  border-color: theme-color(background-color-default);
  background: theme-color(background-color-default);
  border-radius: 0;
  margin-left: 6px;
}

.DateInput_input {
  font-size: 16px;
  padding: 9px 10px;
  border-radius: 0;
  border: none;
  outline: none;
  background: theme-color(background-color-default);
  color: #fff;
}

/* .DateRangePickerInput_arrow {
  display: none;
} */

.CalendarMonth {
  background: theme-color(background-color-default);
}

.CalendarMonthGrid {
  background: theme-color(background-color-default);
}

.DateInput_fang {
  display: none !important;
}

.CalendarDay__default {
  border: 1px solid theme-color(background-color-default);
  color: #fff;
  background: theme-color(background-color-default);
}

.CalendarMonth_caption {
  color: #fff;
}

.DayPicker_transitionContainer {
  border-radius: 0;
  border: 1px solid theme-color(background-color-default);
}

.CalendarMonthGrid__horizontal {
  left: 0px;
}

.DateRangePickerInput_arrow_svg {
  vertical-align: baseline;
}

.DayPickerRangeController {
  box-shadow: none;
}

.DayPickerRangeController__withBorder {
  border-radius: 5px;
  border: 1px solid #000;
}

.DayPicker {
  font-size: 14px;
}

.DayPicker__withBorder {
  box-shadow: none;
}

.DayPicker__weekHeader {
  color: #999;
  font-size: 14px;
}

.DayPickerKeyboardShortcuts_buttonReset {
  display: none;
}

.DayPickerNavigation_button__horizontalDefault {
  border-radius: 5px;
  background-color: #000;
  padding: 10px;
}

.DayPickerNavigation_button__horizontalDefault:hover {
  background-color: theme-color(background-color);
}

.DayPickerNavigation_button__horizontalDefault:focus {
  box-shadow: none;
}

.DayPickerNavigation_button__horizontalDefault:active {
  background-color: theme-color(secondaryWhite);
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background: theme-color(primary);
  border: 1px double theme-color(primary);
  color: #fff;
}

.CalendarDay__selected_span {
  background: #181512;
  border: 1px double #181512;
  color: #fff;
}

.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover {
  background: #181512;
  border: 1px double #181512;
  color: #fff;
}

.CalendarDay__hovered_span,
.CalendarDay__hovered_span:active,
.CalendarDay__hovered_span:hover {
  background: #181512;
  border: 1px double #181512;
  color: #fff;
}

.DateInput {
  width: 110px !important;
}

.DayPicker__horizontal {
  background: theme-color(background-color-default);
  border-radius: 0;
}

.CalendarDay__default:hover {
  background: #181512;
  border: 1px solid theme-color(background-color-default);
}

.CalendarDay__blocked_out_of_range,
.CalendarDay__blocked_out_of_range:active,
.CalendarDay__blocked_out_of_range:hover {
  color: #c4c4c4 !important;
  opacity: 0.4;
}
